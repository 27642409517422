import "./Buttons.scss";

import { ReactNode } from "react";

type ButtonIconProps = {
    icon: ReactNode;
    onClick: () => void;
    styles?: string;
};

export const ButtonIcon = ({ icon, styles, onClick }: ButtonIconProps) => {
    return (
        <button type="button" className={styles} onClick={onClick}>
            {icon}
        </button>
    );
};

import "./Footer.scss";

import { useLocation } from "react-router-dom";

import { RecentServers } from "../RecentServers";
import { Stats } from "../Stats";

export const Footer = () => {
    const location = useLocation().pathname;
    const styles = location === "/" ? "footer-home" : "footer-servers";

    return (
        <div className={`${styles} flex w-full items-end font-light`}>
            {location === "/" && <RecentServers />}
            <Stats />
        </div>
    );
};

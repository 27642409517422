import "./Post.scss";

type PostProps = {
    title: string;
    date: string;
    description: string;
    image: string;
    text: string;
};

export const Post = ({ title, date, description, image, text }: PostProps) => {
    return (
        <div className="post h-p1 rounded-md bg-dark drop-shadow-md md:h-p2 lg:h-p3 xl:h-p4 2xl:h-p5">
            <div className="m-1 md:m-1.5 lg:m-2 xl:m-2 2xl:m-2">
                <div>
                    <div className="flex justify-between">
                        <h1 className="text-xl lg:text-2xl xl:text-2xl 2xl:text-2xl">
                            {title}
                        </h1>
                        <p className="mt-2 text-xs font-light md:text-sm lg:text-sm xl:text-base 2xl:text-base">
                            {date}
                        </p>
                    </div>
                    <p className="mt-2 text-left text-xs font-light md:text-sm lg:text-sm xl:text-base 2xl:text-base">
                        {description}
                    </p>
                </div>
            </div>

            <img className="h-auto max-w-full drop-shadow-md" src={image} />

            <div className="m-1 md:m-1.5 lg:m-2 xl:m-2 2xl:m-2">
                <p
                    className="mt-2 text-left text-xs font-light md:text-sm lg:text-sm xl:text-base 2xl:text-base"
                    dangerouslySetInnerHTML={{ __html: text }}
                ></p>
            </div>
        </div>
    );
};

import { HostNotifications } from "./constants";

declare var callEvent: any;

export class CommBridge {
    private static _instance: CommBridge;

    private constructor() {
        // Bind to the window
        (window as any).CommBridge = this;
    }

    static get Instance(): CommBridge {
        return this._instance || (this._instance = new this());
    }

    notifyHost = (eventName: HostNotifications, data: any): void => {
        if (typeof callEvent === "undefined") {
            return;
        }

        callEvent(eventName, data);
    };
}

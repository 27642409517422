import { Models } from "@rematch/core";

import { core } from "./core";
import { server } from "./server";

export interface RootModel extends Models<RootModel> {
    core: typeof core;
    server: typeof server;
}

const models: RootModel = {
    core,
    server,
};

export default models;

import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import "./Layout.scss";

import bg1 from "../../assets/backgrounds/background_1.jpg";
import bg2 from "../../assets/backgrounds/background_2.jpg";
import bg3 from "../../assets/backgrounds/background_3.jpg";
import bg4 from "../../assets/backgrounds/background_4.jpg";
import bg5 from "../../assets/backgrounds/background_5.jpg";

import { Dispatch } from "store";
import {CommBridge, Firebase, HostNotifications} from "utils";

import {ButtonIcon, ButtonText} from "../Buttons";
import { Footer } from "../Footer";
import {Icons} from "../Icons";
import {Modal} from "../Modal";
import {QuickConnect} from "../QuickConnect";
import {Settings} from "../Settings";

interface IProps {
    children?: React.ReactNode | null;
}

export const Layout = ({ children }: IProps) => {
    const dispatch = useDispatch<Dispatch>();

    // Effects
    useEffect(() => {
        Firebase.Instance.onAuthStateChanged(async (fbUser) => {
            dispatch.core.onAuthStateChanged({ fbUser });
        });
    }, [dispatch]);

    // Methods
    const onClickSandbox = () => {
        CommBridge.Instance.notifyHost(HostNotifications.RUN_SANDBOX, null);
        console.log("connect to sandbox");
    };
    const onClickExit = () => {
        CommBridge.Instance.notifyHost(HostNotifications.EXIT_APP, null);
    };

    // Custom styles
    const backgroundImage = {
        backgroundImage: `url(${[bg1, bg2, bg3, bg4, bg5][Math.floor(Math.random() * 5)]})`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
    };

    return (
        <div className="min-h-screen min-w-full" data-name="Layout">
            <div className="background" style={backgroundImage}></div>
            <div
                className="layout flex min-h-screen w-full flex-col justify-between gap-y-3 px-14 py-3 md:py-5  lg:gap-y-9 lg:py-5 xl:gap-y-9 xl:py-5 2xl:gap-y-9 2xl:py-7"
                data-name="ContentBlock"
            >
                <div className="flex items-center justify-between py-0">
                    <div className="flex w-2/5">
                        <div className="mr-auto">
                            <nav className="flex gap-x-6 font-semibold md:text-xl lg:text-xl xl:text-xl 2xl:text-xl">
                                <ButtonText actionType="link" link="/" text="Home" />
                                <ButtonText actionType="link" link="/servers" text="Servers"/>
                                <Modal
                                    title="Quick Connect"
                                    renderOpenButton={(openModal) => (
                                        <ButtonText onClick={openModal} text="Quick Connect"/>
                                    )}
                                >
                                    <QuickConnect />
                                </Modal>

                                <ButtonText onClick={onClickSandbox} text="Sandbox" />
                            </nav>
                        </div>
                    </div>
                    <div className="flex w-1/5">
                        <div className="nav-logo m-auto h-16 w-full bg-contain bg-center bg-no-repeat md:h-20 lg:h-20 2xl:h-20"></div>
                    </div>
                    <div className="flex w-2/5">
                        <div className="ml-auto">
                            <nav className="flex gap-x-6">
                                <Modal
                                    title="Settings"
                                    renderOpenButton={(openModal) => (
                                        <ButtonIcon
                                            icon={
                                                <Icons
                                                    name="settings"
                                                    width={30}
                                                    height={30}
                                                />
                                            }
                                            styles="settings-btn fill"
                                            onClick={openModal}
                                        />
                                    )}
                                >
                                    <Settings />
                                </Modal>

                                <ButtonIcon icon={<Icons name="exit" width={28} height={28} />} styles="stroke" onClick={onClickExit}/>
                            </nav>
                        </div>
                    </div>
                </div>
                {children}
                <Footer />
            </div>
        </div>
    );
};

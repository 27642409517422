export class Server {
    id: string = "";
    metadata: {
        key: string;
        value: string;
    }[] = [];
    ip: string = "";
    port: number = 0;
    name: string = "";

    getCurrentPlayersFromMetadata(): number {
        const currentPlayers = this.metadata.find(
            (m) => m.key === "current_players",
        );

        if (currentPlayers) {
            return parseInt(currentPlayers.value);
        }

        return 0;
    }

    getMaxPlayersFromMetadata(): number {
        const maxPlayers = this.metadata.find((m) => m.key === "max_players");

        if (maxPlayers) {
            return parseInt(maxPlayers.value);
        }

        return 0;
    }

    getGamemodeFromMetadata(): string {
        const gamemode = this.metadata.find((m) => m.key === "gamemode");

        if (gamemode) {
            return gamemode.value;
        }

        return "";
    }

    getVersionFromMetadata(): string {
        const version = this.metadata.find((m) => m.key === "version");

        if (version) {
            return version.value;
        }

        return "";
    }
}

import { createModel } from "@rematch/core";

import { Server } from "../../models";
import { Services } from "../../utils";

import { RootModel } from ".";

interface ServerState {
    servers: Server[];
}

export const server = createModel<RootModel>()({
    name: "server",
    state: {
        servers: [],
    } as ServerState,
    reducers: {
        SET_SERVERS: (state: ServerState, servers: Server[]) => {
            return {
                ...state,
                servers,
            };
        },
    },
    effects: (dispatch) => ({
        async fetch() {
            try {
                const servers = await Services.Instance.fetchServers();
                dispatch.server.SET_SERVERS(servers);
            } catch (e) {
                console.error(e);
            }
        },
    }),
});

import { Icons } from "../Icons";
import { ButtonIcon } from "../Buttons";
import { ButtonText } from "../Buttons";

type ServerListPanelProps = {
    onClickListBtn?: () => void;
    onClickRefreshBtn?: () => void;
    onClickSortBtn?: () => void;
};

export const ServerListPanel = (props: ServerListPanelProps) => {
    const onClickListBtn = () => {
        if (props.onClickListBtn) {
            props.onClickListBtn();
        }
        return;
    };

    const onClickRefreshBtn = () => {
        if (props.onClickRefreshBtn) {
            props.onClickRefreshBtn();
        }
        return;
    };

    const onClickSortBtn = () => {
        if (props.onClickSortBtn) {
            props.onClickSortBtn();
        }
        return;
    };

    return (
        <div className="flex w-full flex-col">
            <div className="flex flex-col gap-y-3">
                <div className="flex w-full justify-between">
                    <div className="flex gap-x-2">
                        <ButtonIcon
                            onClick={onClickListBtn}
                            icon={
                                <Icons name="slGlobal" width={36} height={36} />
                            }
                            styles="stroke"
                        />
                        <ButtonIcon
                            onClick={onClickListBtn}
                            icon={
                                <Icons
                                    name="slFavorites"
                                    width={36}
                                    height={36}
                                />
                            }
                            styles="stroke"
                        />

                        <ButtonIcon
                            onClick={onClickListBtn}
                            icon={
                                <Icons name="slRecent" width={36} height={36} />
                            }
                            styles="stroke"
                        />
                    </div>
                    <ButtonIcon
                        onClick={onClickRefreshBtn}
                        icon={<Icons name="slRefresh" width={36} height={36} />}
                        styles="stroke"
                    />
                </div>
                <div className="flex w-full rounded-md bg-dark p-2 drop-shadow-md">
                    <div className="flex w-4/12 gap-x-4">
                        <ButtonText text="Rg:" onClick={onClickSortBtn} />
                        <ButtonText text="Name:" onClick={onClickSortBtn} />
                    </div>
                    <div className="flex w-8/12 justify-between">
                        <div className="w-1/4">
                            <ButtonText text="Mode:" onClick={onClickSortBtn} />
                        </div>
                        <div className="w-1/4">
                            <ButtonText
                                text="Players:"
                                onClick={onClickSortBtn}
                            />
                        </div>
                        <div className="w-1/4">
                            <ButtonText text="Ping:" onClick={onClickSortBtn} />
                        </div>
                        <div className="w-1/4">
                            <ButtonText
                                text="Favorites:"
                                onClick={onClickSortBtn}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

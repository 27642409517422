import { FirebaseApp, initializeApp } from "firebase/app";
import {
    Auth,
    User,
    UserCredential,
    initializeAuth,
    onAuthStateChanged,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signOut,
    browserLocalPersistence,
} from "firebase/auth";

export class Firebase {
    private static _instance?: Firebase;

    private readonly _app: FirebaseApp;
    private readonly _auth: Auth;

    constructor() {
        this._app = initializeApp({
            apiKey: "AIzaSyCparQHsiCHVkWuiwzAzS64D32OEsY1oLc",
            authDomain: "mafiahub-3ebed.firebaseapp.com",
            databaseURL:
                "https://mafiahub-3ebed-default-rtdb.europe-west1.firebasedatabase.app",
            projectId: "mafiahub-3ebed",
            storageBucket: "mafiahub-3ebed.appspot.com",
            messagingSenderId: "546323083920",
            appId: "1:546323083920:web:6716980a4d6412e87e1e15",
            measurementId: "G-1ZQ0JLQ9TP",
        });

        this._auth = initializeAuth(this._app, {
            persistence: browserLocalPersistence,
        });
    }

    get app(): FirebaseApp {
        return this._app;
    }

    get auth(): Auth {
        return this._auth;
    }

    get currentUser(): User | null {
        return this._auth.currentUser;
    }

    static get Instance(): Firebase {
        return this._instance || (this._instance = new this());
    }

    onAuthStateChanged = (callback: (user: User | null) => void): void => {
        if (this.auth) {
            onAuthStateChanged(this.auth, callback);
        }
    };

    createUserWithEmailAndPassword = async (
        email: string,
        password: string,
    ): Promise<UserCredential> => {
        return createUserWithEmailAndPassword(this.auth, email, password);
    };

    signInWithEmailAndPassword = async (
        email: string,
        password: string,
    ): Promise<UserCredential> => {
        return signInWithEmailAndPassword(this.auth, email, password);
    };

    signOut = async (): Promise<void> => {
        return signOut(this.auth);
    };
}

import { Content, ServerList } from "components";

export const Servers = () => {
    return (
        <>
            <Content name="Server List:">
                <ServerList />
            </Content>
        </>
    );
};

import postImg1 from "../assets/images/post_1.jpg";

import { Content, News, Post } from "components";

export const Home = () => {
    return (
        <>
            <Content name="Welcome to<br>Mafia Multiplayer!">
                <News>
                    <Post
                        title="MafiaMP is now available!"
                        date="27.04.2023"
                        description="Welcome to the long-awaited Mafia Multiplayer! We are glad to announce that the mod is now available for everyone. You can download the game from our website and start playing right now. We hope you will enjoy the game and have a great time with your friends. Don't forget to share your feedback with us. Have fun!"
                        image={postImg1}
                        text=""
                    />
                </News>
            </Content>
        </>
    );
};

import "./Recent.scss";

import { Icons } from "../../Icons";

type RecentProps = {
    name: string;
    players: string;
    ping: number;
};

export const Recent = ({ name, players, ping }: RecentProps) => {
    const color =
        ping <= 150
            ? "#447E29"
            : 150 < ping && ping <= 300
              ? "#7E7629"
              : "#7E2929";

    const onClickServer = () => {
        console.log("Recent server");
    };

    return (
        <button type="button" onClick={onClickServer} className="w-full">
            <div className="recentServer flex w-full items-center justify-between rounded-md bg-dark px-1 py-1.5 text-xs drop-shadow-md md:text-sm lg:px-1.5 lg:py-2.5 lg:text-sm xl:py-2.5 xl:text-sm 2xl:py-2.5 2xl:text-sm">
                <p>{name}</p>

                <div className="flex w-6/12 justify-end gap-y-2">
                    <div className="flex w-6/12 items-center justify-end gap-x-1">
                        <p>{players}</p>
                        <Icons name="player" width={16} height={20} />
                    </div>
                    <div className="flex min-w-11 items-center justify-end gap-x-1">
                        <p>{ping}</p>
                        <Icons
                            name="circle"
                            width={9}
                            height={9}
                            color={color}
                        />
                    </div>
                </div>
            </div>
        </button>
    );
};

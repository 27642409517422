type StatsProps = {
    servers?: number;
    players?: number;
};

export const Stats = ({ servers = 1024, players = 512 }: StatsProps) => {
    return (
        <div className="text-sm font-light md:text-base lg:text-base xl:text-base 2xl:text-base">
            <p className="text-right">Servers online: {servers}</p>
            <p className="text-right">Players online: {players}</p>
        </div>
    );
};

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Dispatch, RootState } from "store";
import { Server as ServerModel } from "models";
import { CommBridge, HostNotifications } from "utils";

import { ServerListPanel } from "../ServerListPanel";
import { Server } from "../Servers";

export const ServerList = () => {
    // Hooks
    const dispatch = useDispatch<Dispatch>();
    const serverSelector = useSelector(
        (state: RootState) => state.server.servers,
    );
    const currentServiceUser = useSelector((state: RootState) => state.core.serviceUser);

    // Methods
    const onClickRefreshServerList = () => {
        dispatch.server.fetch();
    };
    const onClickConnect = (server: ServerModel) => {
        const payload = {
            host: String(server.ip),
            port: Number(server.port),
            user: currentServiceUser ? {
                id: currentServiceUser.id,
                name: currentServiceUser.name_to_display
            } : null
        };

        CommBridge.Instance.notifyHost(
            HostNotifications.CONNECT_TO_HOST,
            JSON.stringify(payload),
        );
    };

    // Effects
    useEffect(() => {
        onClickRefreshServerList();
    }, []);

    return (
        <div className="flex flex-col gap-y-6">
            <ServerListPanel onClickRefreshBtn={() => onClickRefreshServerList()} />
            <div className="flex flex-col gap-y-1.5">
                {serverSelector.map((server, index) => (
                    <Server
                        key={server.id}
                        region={"EU"}
                        name={server.name}
                        mode={server.getGamemodeFromMetadata() || "Unknown"}
                        players={String(
                            server.getCurrentPlayersFromMetadata() ||
                                "0" +
                                    " / " +
                                    server.getMaxPlayersFromMetadata() ||
                                "0",
                        )}
                        ping={0}
                        favorites={"No"}
                        onClick={() => onClickConnect(server)}
                    />
                ))}
            </div>
        </div>
    );
};

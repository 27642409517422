import "./Buttons.scss";

import { Link } from "react-router-dom";

type ButtonTextProps = {
    disabled?: boolean;
    text: string;
    actionType?: string;
    link?: string;
    onClick?: () => void;
    styles?: string;
};

export const ButtonText = ({
    actionType = "click",
    disabled,
    link,
    text,
    onClick,
    styles,
}: ButtonTextProps) => {
    const customProps =
        actionType === "click"
            ? { content: text, onClick }
            : {
                  content: <Link to={{ pathname: link }}>{text}</Link>,
                  onClick: undefined,
              };

    return (
        <button
            type="button"
            onClick={customProps.onClick}
            className={styles}
            disabled={disabled}
        >
            {customProps.content}
        </button>
    );
};

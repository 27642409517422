import { RematchDispatch, RematchRootState, init } from "@rematch/core";
import loadingPlugin, { ExtraModelsFromLoading } from "@rematch/loading";
import updatedPlugin, { ExtraModelsFromUpdated } from "@rematch/updated";

import models, { RootModel } from "./models";

type FullModel = ExtraModelsFromLoading<RootModel> &
    ExtraModelsFromUpdated<RootModel>;

const store = init<RootModel, FullModel>({
    models,
    plugins: [loadingPlugin(), updatedPlugin()],
});

export type Store = typeof store;
export type Dispatch = RematchDispatch<RootModel>;
export type RootState = RematchRootState<RootModel, FullModel>;

export default store;

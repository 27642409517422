import "./Input.scss";

type InputProps = {
    placeholder: string;
    defaultValue?: string | number;
    styles?: string;
    inputType?: string;
    error?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const Input = ({
    placeholder,
    defaultValue,
    styles,
    inputType = "text",
    error,
    onChange,
}: InputProps) => {
    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.isTrusted) {
            return;
        }

        onChange && onChange(e);
    };

    return (
        <input
            className={`${styles} h-9 rounded-md border-none bg-gray px-2 text-left text-base font-normal text-dark drop-shadow-md placeholder:text-base placeholder:font-normal placeholder:text-dark md:h-11 lg:h-11 lg:text-xl lg:placeholder:text-lg xl:h-11 xl:text-xl xl:placeholder:text-lg 2xl:h-11 2xl:text-xl 2xl:placeholder:text-lg`}
            type={inputType}
            id="textInput"
            placeholder={placeholder}
            defaultValue={defaultValue}
            onChange={handleOnChange}
            autoComplete="off"
        />
    );
};

export class EventManager {
    private static _instance: EventManager;

    private readonly _onceEvents: { [key: string]: Function[] } = {};
    private readonly _permanentEvents: { [key: string]: Function[] } = {};

    private constructor() {
        // Bind to the window
        (window as any).EventManager = this;
    }

    static get Instance(): EventManager {
        return this._instance || (this._instance = new this());
    }

    on = (eventName: string, callback: Function): void => {
        try {
            const e =
                this._permanentEvents[eventName] ||
                (this._permanentEvents[eventName] = []);
            if (e !== undefined) {
                e.push(callback);
            }
        } catch (e) {
            console.error(e);
        }
    };

    once = (eventName: string, callback: Function): void => {
        try {
            const e =
                this._onceEvents[eventName] ||
                (this._onceEvents[eventName] = []);
            if (e !== undefined) {
                e.push(callback);
            }
        } catch (e) {
            console.error(e);
        }
    };

    off = (eventName: string): void => {
        try {
            delete this._onceEvents[eventName];
            delete this._permanentEvents[eventName];
        } catch (e) {
            console.error(e);
        }
    };

    emit = (eventName: string, data: any): void => {
        try {
            // Emit permanent events
            const e = this._permanentEvents[eventName];
            if (e !== undefined) {
                e.forEach((callback) => {
                    callback(data);
                });
            }

            // Emit once events
            const oe = this._onceEvents[eventName];
            if (oe !== undefined) {
                oe.forEach((callback) => {
                    callback(data);
                });
                delete this._onceEvents[eventName];
            }
        } catch (e) {
            console.error(e);
        }
    };
}

import "./Server.scss";

type ServerProps = {
    region: string;
    name: string;
    mode: string;
    players: string;
    ping: number;
    favorites: string;
    onClick: () => void;
};

export const Server = ({
    region,
    name,
    mode,
    players,
    ping,
    favorites,
    onClick,
}: ServerProps) => {
    return (
        <button onClick={onClick}>
            <div className="server flex w-full rounded-md bg-dark p-2 drop-shadow-md">
                <div className="flex w-4/12 gap-x-4">
                    <p>{region}</p>
                    <p>{name}</p>
                </div>
                <div className="flex w-8/12 justify-between">
                    <div className="w-1/4">
                        <p>{mode}</p>
                    </div>
                    <div className="w-1/4">
                        <p>{players}</p>
                    </div>
                    <div className="w-1/4">
                        <p>{ping}</p>
                    </div>
                    <div className="w-1/4">
                        <p>{favorites}</p>
                    </div>
                </div>
            </div>
        </button>
    );
};

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";

import { Layout } from "components";
import { Home, Servers } from "pages";

import store from "./store";

export const App = () => {
    return (
        <ReduxProvider store={store}>
            <BrowserRouter>
                <Layout>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/servers" element={<Servers />} />
                    </Routes>
                </Layout>
            </BrowserRouter>
        </ReduxProvider>
    );
};

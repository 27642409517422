import { useFormik } from "formik";
import * as yup from "yup";
import { useSelector } from "react-redux";

import "./QuickConnect.scss";

import { RootState } from "store";
import { CommBridge, HostNotifications } from "utils";

import { ButtonText } from "../Buttons";
import { Input } from "../Input";

export const QuickConnect = () => {
    const currentServiceUser = useSelector((state: RootState) => state.core.serviceUser);
    const quickConnectForm = useFormik({
        initialValues: {
            host: "127.0.0.1",
            port: 27015,
        },
        validationSchema: yup.object().shape({
            host: yup.string().required("Host is required"),
            port: yup.number().required("Port is required"),
        }),
        onSubmit: (values) => {
            const payload = {
                host: String(values.host),
                port: Number(values.port),
                user: currentServiceUser ? {
                    id: currentServiceUser.id,
                    name: currentServiceUser.name_to_display
                } : null
            };
            
            CommBridge.Instance.notifyHost(
                HostNotifications.CONNECT_TO_HOST,
                JSON.stringify(payload),
            );
        },
    });

    return (
        <div>
            <form
                className="flex flex-col gap-y-2 md:gap-y-2 lg:gap-y-3 xl:gap-y-3 2xl:gap-y-3"
                onSubmit={quickConnectForm.handleSubmit}
            >
                <div className="flex gap-x-3">
                    <Input
                        placeholder="IP Address"
                        defaultValue={quickConnectForm.initialValues.host}
                        styles="w-8/12"
                        onChange={(e) =>
                            quickConnectForm.setFieldValue(
                                "host",
                                e.target.value,
                            )
                        }
                        error={
                            quickConnectForm.touched.host
                                ? quickConnectForm.errors.host
                                : ""
                        }
                    />
                    <Input
                        placeholder="Port"
                        defaultValue={quickConnectForm.initialValues.port}
                        styles="w-4/12"
                        inputType="number"
                        onChange={(e) =>
                            quickConnectForm.setFieldValue(
                                "port",
                                e.target.value,
                            )
                        }
                        error={
                            quickConnectForm.touched.port
                                ? quickConnectForm.errors.port
                                : ""
                        }
                    />
                </div>
                <ButtonText
                    text="Connect"
                    onClick={() => quickConnectForm.submitForm()}
                    styles="w-full text-lg connect-btn h-9 rounded-md lg:text-xl xl:text-xl 2xl:text-xl lg:h-11 xl:h-11 2xl:h-11 md:h-11"
                />
            </form>
        </div>
    );
};

import { Recent } from "../Servers";

export const RecentServers = () => {
    return (
        <div className="flex w-2/5 flex-col items-start gap-y-1.5 lg:w-2/6 lg:gap-y-2 xl:w-2/6 xl:gap-y-2 2xl:w-2/6 2xl:gap-y-2">
            <h1 className="text-base font-semibold lg:text-xl 2xl:text-xl">
                Recent Servers:
            </h1>
            <div className="flex w-full flex-col gap-y-1 lg:gap-y-2 xl:gap-y-2 2xl:gap-y-2">
                <Recent
                    name="MafiaHUB - Deathmatch"
                    players="13 / 100"
                    ping={158}
                />
                <Recent
                    name="MafiaHUB - Deathmatch"
                    players="24 / 150"
                    ping={13}
                />
                <Recent
                    name="MafiaHUB - Deathmatch"
                    players="78 / 200"
                    ping={312}
                />
            </div>
        </div>
    );
};

import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";

import { Dispatch, RootState } from "store";

import { Input } from "../Input";
import { ButtonText } from "../Buttons";

export const Settings = () => {
    const authLoading = useSelector(
        (state: RootState) => state.core.authLoading,
    );
    const currentAuthUser = useSelector((state: RootState) => state.core.authUser);
    const dispatch = useDispatch<Dispatch>();

    const loginForm = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: yup.object().shape({
            email: yup.string().required("Email is required"),
            password: yup.string().required("Password is required"),
        }),
        onSubmit: (values) => {
            dispatch.core.signInWithEmailAndPassword({
                email: values.email,
                password: values.password,
            });
        },
    });

    // If the user is actually logged in, we display the logged in widget
    if (currentAuthUser) {
        return (
            <div>
                <span>Hello {currentAuthUser.email}</span>
                <ButtonText
                    text="Sign Out"
                    onClick={() => dispatch.core.signOut()}
                    styles="w-full connect-btn h-11 rounded-md text-xl"
                />
            </div>
        );
    }

    // Otherwise, we display the login widget
    return (
        <div>
            <form
                className="flex flex-col gap-y-3"
                onSubmit={loginForm.handleSubmit}
            >
                <div className="flex gap-x-3">
                    <Input
                        placeholder="Email Address"
                        defaultValue={loginForm.initialValues.email}
                        styles="w-6/12"
                        onChange={(e) =>
                            loginForm.setFieldValue("email", e.target.value)
                        }
                        error={
                            loginForm.touched.email
                                ? loginForm.errors.email
                                : ""
                        }
                    />
                    <Input
                        placeholder="Password"
                        defaultValue={loginForm.initialValues.password}
                        styles="w-6/12"
                        inputType="password"
                        onChange={(e) =>
                            loginForm.setFieldValue("password", e.target.value)
                        }
                        error={
                            loginForm.touched.password
                                ? loginForm.errors.password
                                : ""
                        }
                    />
                </div>
                <ButtonText
                    disabled={authLoading}
                    text="Sign In"
                    onClick={() => loginForm.submitForm()}
                    styles="w-full text-lg connect-btn h-9 rounded-md lg:text-xl xl:text-xl 2xl:text-xl lg:h-11 xl:h-11 2xl:h-11 md:h-11"
                />
            </form>
        </div>
    );
};

import { createModel } from "@rematch/core";
import { User as AuthUser } from "firebase/auth";

import { RootModel } from ".";
import { Firebase, Services } from "utils";
import { User } from "models/user";

interface CoreState {
    authLoading: boolean;
    authUser: AuthUser | null;
    serviceUser: User | null;
}

export const core = createModel<RootModel>()({
    name: "core",
    state: {
        authLoading: false,
        authUser: null,
    } as CoreState,
    reducers: {
        SET_AUTH_LOADING: (state, authLoading: boolean) => {
            return {
                ...state,
                authLoading,
            };
        },
        SET_AUTH_USER: (state, authUser: AuthUser | null) => {
            return {
                ...state,
                authUser,
            };
        },
        SET_SERVICE_USER: (state, serviceUser: User | null) => {
            return {
                ...state,
                serviceUser,
            };
        },
    },
    effects: (dispatch) => ({
        async onAuthStateChanged({ fbUser }: { fbUser: AuthUser | null }) {
            dispatch.core.SET_AUTH_USER(fbUser);

            if(fbUser){
                dispatch.core.getMe();
            } else {
                dispatch.core.SET_SERVICE_USER(null);
            }
        },
        async signInWithEmailAndPassword({
            email,
            password,
        }: {
            email: string;
            password: string;
        }) {
            dispatch.core.SET_AUTH_LOADING(true);

            Firebase.Instance.signInWithEmailAndPassword(email, password)
                .catch((error) => {
                    dispatch.core.handleAuthError(error.code);
                })
                .finally(() => {
                    dispatch.core.SET_AUTH_LOADING(false);
                });
        },
        async signOut() {
            Firebase.Instance.signOut().catch((error) => {
                dispatch.core.handleAuthError(error.code);
            });
        },
        async getMe() {
            try {
                const me = await Services.Instance.getMe();
                dispatch.core.SET_SERVICE_USER(me);
            } catch(e){
                console.error(e);
            }
        },
        handleAuthError(error: any) {
            console.log("handleAuthError", error);
        },
    }),
});

export class User {
    discord_id: string = '';
    discord_username: string = '';

    display_name: string = '';

    email: string = '';
    id: string = '';

    is_banned: boolean = false;

    name_to_display: string = '';
}
import "./Content.scss";

import { ReactNode } from "react";

type ContentProps = {
    name: string;
    children: ReactNode;
};

export const Content = ({ name, children }: ContentProps) => {
    return (
        <div
            className="content flex grow flex-col gap-y-3 md:gap-y-9 lg:gap-y-9 xl:gap-y-9 2xl:gap-y-9"
            data-name="Content"
        >
            <h1
                className="text-left text-2xl font-normal md:text-3xl lg:text-4xl xl:text-4xl 2xl:text-4xl"
                dangerouslySetInnerHTML={{ __html: name }}
            />
            <div className="flex flex-col">{children}</div>
        </div>
    );
};

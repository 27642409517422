import { ReactNode, useState, Fragment } from "react";

import { Dialog, Transition } from "@headlessui/react";

import { ButtonIcon } from "../Buttons";
import { Icons } from "../Icons";

type ModalProps = {
    title: string;
    children: ReactNode;
    renderOpenButton: (arg0: () => void) => ReactNode;
};

export const Modal = ({ title, children, renderOpenButton }: ModalProps) => {
    let [isOpen, setIsOpen] = useState(false);

    const handleOpen = () => setIsOpen(true);
    const handleClose = () => setIsOpen(false);

    return (
        <>
            {renderOpenButton(handleOpen)}
            {isOpen && (
                <Transition
                    appear
                    show={isOpen}
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-500"
                    leaveFrom="opacity-200"
                    leaveTo="opacity-0"
                >
                    <Dialog
                        as="div"
                        onClose={handleClose}
                        className="relative z-50 flex h-screen flex-col items-center justify-center bg-dark"
                    >
                        <div className="flex max-w-sm items-center justify-center rounded-md bg-dark_xl p-4 text-center drop-shadow-md xl:min-w-96 xl:max-w-lg 2xl:min-w-96 2xl:max-w-lg">
                            <Dialog.Panel className="flex w-full flex-col gap-y-5 md:gap-y-7 lg:gap-y-8 xl:gap-y-8 2xl:gap-y-8">
                                <div className="flex items-center justify-between">
                                    <Dialog.Title className="text-xl font-light md:text-2xl xl:text-2xl 2xl:text-2xl">
                                        {title}
                                    </Dialog.Title>
                                    <ButtonIcon
                                        icon={
                                            <Icons
                                                name="exit"
                                                width={22}
                                                height={22}
                                            />
                                        }
                                        styles="exit-btn"
                                        onClick={handleClose}
                                    />
                                </div>
                                {children}
                            </Dialog.Panel>
                        </div>
                    </Dialog>
                </Transition>
            )}
        </>
    );
};
